import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes, UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router';
import { AuthGuard } from 'projects/apna-auth/src/app/auth/auth.guard';
import { AuthorizationGuard } from 'projects/apna-auth/src/app/auth/authorization.guard';
import { PageComponent } from 'projects/website-cms/src/app/front-end/page/page.component';

const routes: Routes = [
  {
    path: 'dashboard',
    data: {
      title: "Dashboard"
    },
    canActivate: [AuthGuard, AuthorizationGuard],
    canActivateChild: [AuthGuard, AuthorizationGuard],
    loadChildren: () =>
      import(`./dashboard/dashboard.module`).then((m) => m.DashboardModule),
  },
  /* {
    path: '',
    component: PageComponent
  },
  {
    path: ':pageUrl',
    component: PageComponent
  },
  {
    path: 'p/:pageUrl',
    component: PageComponent
  }, */
  {
    path: 'citizen/dashboard',
    data: {
      title: "Dashboard"
    },
    canActivate: [AuthGuard, AuthorizationGuard],
    canActivateChild: [AuthGuard, AuthorizationGuard],
    loadChildren: () =>
      import(`projects/citizen/src/app/dashboard/dashboard.module`).then((m) => m.DashboardModule),
  },
  // {
  //   path: 'recruitment',
  //   loadChildren: () => import('projects/accounting/src/app/modules/hr/recruitment/recruitment.module').then(m => m.RecruitmentModule)
  // },
  {
    path: 'citizen',
    loadChildren: () => import('projects/citizen/src/app/front-end/front-end.module').then(m => m.FrontEndModule)
  },
  {
    // User Referral link matcher
    matcher: (segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult => {
      let regx = new RegExp("^[A-Z0-9]{8,}$");
      if (segments.length == 1 && regx.test(segments[0].path)) {
        return { consumed: segments, posParams: { puid: segments[0] } };
      } else {
        return null;
      }
    },
    redirectTo: '/auth/quick-register/:puid'
  },
  {
    // Client/Partner/Vendor Referral link matcher
    matcher: (segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult => {
      let regx = new RegExp("^[A-Z0-9]{8,}$");
      if (segments.length == 2 && regx.test(segments[0].path) && ['Partner', 'Client', 'Vendor'].includes(segments[1].path)) {
        return { consumed: segments, posParams: { type: segments[1], puid: segments[0] } };
      } else {
        return null;
      }
    },
    redirectTo: '/auth/register/:type/:puid'
  },
  {
    path: '',
    loadChildren: () => import('projects/website-cms/src/app/front-end/front-end.module').then(m => m.FrontEndModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '**',
    loadChildren: () =>
      import('projects/apna-lib/src/lib/components/page404/page404.module').then((m) => m.Page404Module),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
