import { Injectable } from '@angular/core';
import { UserIdentityService } from './user-identity.service';
import { UserDetails } from '../interfaces/user-details';
import { ApiHelperService } from './api-helper.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
@Injectable({
  providedIn: 'platform'
})
export class AuthService {

  private _userPermissions: any;
  private _userPermissionsSubject: BehaviorSubject<boolean | object> = new BehaviorSubject(false);
  private _roles: string[];
  private _role: string;

  constructor(
    private service: ApiHelperService,
    private user: UserIdentityService
  ) {
    this.user.isLoggedIn.subscribe(loggedIn => {
      if(loggedIn === true) {
        this.getUserPermissions();
      } else { 
        this.permissions = null;
        this._userPermissionsSubject.next({});
      }
    })
  }

  private get userDetails(): UserDetails {
    return JSON.parse(localStorage.getItem('userDetails'))
  }

  public get role() {
    return this._role;
  }

  public set role(role) {
    this._role = role;
  }

  private get otherDetailsSubmitted() {
    return this.userDetails.isOtherDetailsSubmitted;
  }

  public get permissions() {
    return this._userPermissions;
  }

  private set permissions(permissions) {
    this._userPermissions = permissions;
  }

  public get userPermissionsSubject() {
    return this._userPermissionsSubject.asObservable();
  }

  public get roles() {
    return this._roles;
  }

  public set roles(roles) {
    this._roles = roles;
  }

  async getUserPermissions() {
    let userId = localStorage.getItem('userId');
    if (!userId) return this.permissions = null;
    if (this.permissions) return this.permissions;
    const res: any = await this.service.getData(`users/${userId}/permissions`);
    if (res) {
      this.roles = res.Roles;
      this.role = res.Roles.filter(item => item == res.type)[0] || res.Roles[0];
      let permissions = {};
      for (let key in res.Permissions) {
        permissions[key.replace(/\_/g, '.')] = res.Permissions[key];
      }
      this._userPermissions = permissions;
      this._userPermissionsSubject.next(this.permissions);
    } else {
      this._userPermissionsSubject.next({});
    }
    return this.permissions;
  }

  hasPermission(permission: string | string[]) {
    if (typeof permission === undefined || permission == null) return true;
    if (!this.permissions) return false;
    let hasPermission = false;
    if (Array.isArray(permission)) {
      hasPermission = permission.reduce((prev, curr) => {
        // console.log('in reduce', prev, curr, this.permissions[userId][curr]);
        return prev || this.permissions[curr];
      }, false);
      // console.log('insideArr', hasPermission);
    } else if (typeof permission === "string") {
      hasPermission = this.permissions[permission];
      // console.log('inside string', hasPermission);
    }
    return hasPermission;
  }

  hasNotPermission(permission: string | string[]) {
    return this.hasPermission(permission) ? false : true;
  }

  isUserRole(role: string | string[]) {
    if (Array.isArray(role)) {
      return role.includes(this.role);
    } else {
      return this.role === role;
    }
  }

  isOtherDetailsSubmitted() {
    return this.otherDetailsSubmitted;
  }

}
