import { Injectable, OnDestroy, Optional, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { map, concatMap, tap } from 'rxjs/operators';
import { AccessToken, FreshToken } from '../interfaces/user-details';
import { ApiHelperService } from './api-helper.service';
import { TimerService } from './timer-service';
import { differenceInSeconds } from "date-fns";
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class UserIdentityService implements OnDestroy {
  public isLoggedIn = new BehaviorSubject(false);
  private _userDetails: any = null;
  private jwtTimer = new TimerService(1000);
  private jwtSubscription: Subscription;
  private renderer: Renderer2;
  private lastInteraction: Date;

  constructor(
    private apiHelper: ApiHelperService,
    private rendererFactory2: RendererFactory2,
    private router: Router,
    @Optional() private matDialog: MatDialog
  ) {
    this.renderer = this.rendererFactory2.createRenderer(null, null);
    this.getUserDetails();
  }

  ngOnDestroy(): void {
    if (this.jwtSubscription) {
      this.jwtSubscription.unsubscribe();
    }
  }

  private getUserDetails() {
    if (this.isValidToken()) {
      this.isLoggedIn.next(true);
      this._userDetails = JSON.parse(localStorage.getItem('userDetails'));
      this.listenToUserActivity();
      // this.startJwtTimer();
    } else {
      this._userDetails = null;
      this.clearUserDetails();
    }
    return this._userDetails;
  }

  // TODO: Remove this method and use setter for userDetails i.e. set userDetails
  public setUserDetails(user) {
    let userDetails = this._userDetails;
    Object.assign(userDetails, user);
    localStorage.setItem('userDetails', JSON.stringify(userDetails));
    this._userDetails = userDetails;
  }

  public async updatetUserDetails(userDetails: any, updateData: any) {
    return this.apiHelper.updateData('users', userDetails.id, updateData).pipe(tap(res => {
      console.log(res);
      this.setUserDetails(res);
    })).toPromise()
  }

  get roleName() {
    return localStorage.getItem('roleName');
  }

  get userDetails() {
    return this._userDetails;
  }

  isOthersDetailsSubmitted() {
    return this._userDetails && this._userDetails.isOtherDetailsSubmitted;
  }


  storeUserDetails(accessToken) {
    localStorage.setItem('token', accessToken.id.toString());
    localStorage.setItem('userId', accessToken.userId.toString());
    localStorage.setItem('ttl', accessToken.ttl.toString());
    localStorage.setItem('created', accessToken.created.toString());
    if (accessToken.refreshToken)
      localStorage.setItem('refreshToken', accessToken.refreshToken.toString());
    if (accessToken.userDetails) {
      localStorage.setItem('userDetails', JSON.stringify(accessToken.userDetails));
    }
    if (accessToken.userDetails?.companyId) {
      localStorage.setItem('companyId', accessToken.userDetails.companyId);
    }
    this._userDetails = accessToken.userDetails;
    this.isLoggedIn.next(true);
  }

  login(credentails: any) {
    console.log(credentails)
    return new Promise((resolve, reject) => {
      this.apiHelper.postData("users/login", credentails).subscribe((accessToken: AccessToken) => {
        this.storeUserDetails(accessToken);
        // this.startJwtTimer();
        resolve(accessToken.userDetails);
        this.apiHelper.openSnackBar('Login Successfully!');
      }, err => {
        reject(err);
        let errMsg = err.error.error ? err.error.error.message : err.message;
        this.apiHelper.openSnackBar(errMsg, { duration: 6000 });
      });
    });
  }


  async logout() {
    if (localStorage.getItem('userId')) {
      try {
        let logoutRes = await this.apiHelper.getData('users/logout', {})
      } catch (e) {
        console.log(e)
      }
    }
    this.clearUserDetails();
    if (this.matDialog) {
      this.matDialog.closeAll();
    }
    this.router.navigate(['/auth/logout']);

  }

  clearUserDetails() {
    this._userDetails = null;
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('ttl');
    localStorage.removeItem('created');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userDetails');
    localStorage.removeItem('companyId');
    localStorage.removeItem('order');
    this.isLoggedIn.next(false);
  }


  register(userDetails: any) {
    return new Promise((resolve, reject) => {
      this.apiHelper.postData("users/verifyOtp", userDetails).subscribe((accessToken: AccessToken) => {
        this.storeUserDetails(accessToken);
        resolve(accessToken.userDetails);
        this.apiHelper.openSnackBar('Login Successfully!');
      }, err => {
        reject(err);
        let errMsg = err.error.error ? err.error.error.message : err.message;
        this.apiHelper.openSnackBar(errMsg, { duration: 6000 });
      });
    });
  }

  quickRegister(userDetails: any) {
    return this.apiHelper.postData("users", userDetails);
  }

  isValidToken() {
    let token = localStorage.getItem('token')
    if (token) {
      const expiry = this.getTokenExpiry();
      return (Math.floor((new Date).getTime() / 1000)) <= expiry;
    } else {
      return false;
    }
  }

  getTokenExpiry() {
    let token = localStorage.getItem('token');
    const expiry = JSON.parse(atob(token.split('.')[1])).exp;
    return expiry;
    // return new Date(localStorage.getItem('created')).getTime() - (Number(localStorage.getItem('ttl')) * 1000);
  }

  isValidRefreshToken() {
    let refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      const expiry = JSON.parse(atob(refreshToken.split('.')[1])).exp;
      return (Math.floor((new Date).getTime() / 1000)) <= expiry;
    } else {
      return false;
    }
  }

  sendOtp(mobile: string) {
    return this.apiHelper.getData(`users/otp/${mobile}`);
  }

  verifyOtp(mobileOtp: any) {
    return this.apiHelper.postData(`/users/login-with-mobile-otp`, mobileOtp);
  }

  isJwtTokenAboutToExpiry() {
    let token = localStorage.getItem('token');
    if (token) {
      let expiryTimeSec = this.getTokenExpiry(); // Expiry time is in sec
      let thresholdTimeSec = expiryTimeSec - 60; // Thresold is 1 min less than expiry
      let currentTimeSec = Math.floor(new Date().getTime() / 1000); // Current Time from milliseconds to seconds
      return currentTimeSec > thresholdTimeSec;
    } else {
      return false;
    }
  }

  startJwtTimer() {
    this.jwtSubscription = this.jwtTimer.observable$.pipe(
      map(count => {
        let isTokenValid = this.isValidToken();
        if (isTokenValid) {
          return count;
        } else {
          throw new Error("jwtExpired");
        }
      }),
      map(count => {
        let isUserInactive = this.isUserInActive();
        if (isUserInactive) {
          throw new Error("userIsInactive");
        } else {
          return count;
        }
      }),
      concatMap((count: any) => {
        let isTokenAboutToExpiry = this.isJwtTokenAboutToExpiry();
        if (isTokenAboutToExpiry) {
          return this.refreshToken();
        } else {
          return of(count);
        }
      })).subscribe(tokenRefreshed => {
        if (tokenRefreshed === true) {
          this.jwtTimer.stop();
          this.jwtTimer.start();
        }
      }, error => {
        this.jwtSubscription.unsubscribe();
        this.logout();
      });
  }

  refreshToken(): Observable<boolean> {
    return this.apiHelper.postData('users/refresh', {
      refreshToken: localStorage.getItem('refreshToken')
    }).pipe(map((fresh: FreshToken) => {
      localStorage.setItem("token", fresh.id);
      localStorage.setItem("refreshToken", fresh.refreshToken);
      return true;
    }));
  }

  private listenToUserActivity() {
    this.renderer.listen('document', 'mousemove', (evt) => {
      this.lastInteraction = new Date();
    });
    this.renderer.listen('document', 'click', (evt) => {
      this.lastInteraction = new Date();
    });
    this.renderer.listen('document', 'keydown', (evt) => {
      this.lastInteraction = new Date();
    });
  }

  private isUserInActive() {
    let idleTimeInSeconds = differenceInSeconds(new Date(), this.lastInteraction ?? new Date());
    let maximumIdleTimeAllowedInSeconds = 14 * 60;
    if (idleTimeInSeconds > maximumIdleTimeAllowedInSeconds) {
      return true;
    } else {
      return false;
    }
  }

  public getCallingDetails() {
    return this.apiHelper.getDataById('hr-staffs', this.userDetails.id);
  }

}
