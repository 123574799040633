import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PwaServiceService {

  constructor(private swUpdate: SwUpdate) {
    if (this.swUpdate.isEnabled) {
      // Required to enable updates on Windows and ios.
      this.swUpdate.activateUpdate();
      setInterval(() => {
        this.swUpdate.checkForUpdate().then((isUpdateAvailable) => {
          console.log('checked for updates', isUpdateAvailable);
          if (isUpdateAvailable) {
            this.updateApp();
          }
        });
      }, 1000 * 60 * 60); // every one hour 
    }

    swUpdate.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map(evt => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion,
      })))
      .subscribe(event => {
        this.updateApp();
      });
  }

  updateApp() {
    let conf = confirm('New update is available. Do you want to update your application?');
    if (conf) {
      window.location.reload();
    }
  }
}
