import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class Interceptor implements HttpInterceptor {
    constructor() { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('token');
        const companyId = localStorage.getItem('companyId');
        const userId = localStorage.getItem('userId');
        let customReq = request;
        if (token) {
            const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
            customReq = customReq.clone({ headers });
        }
        let params = request.params;
        if (companyId) {
            params = params.set('companyId', companyId);
        }
        if (userId) {
            params = params.set('userId', userId);
        }
        customReq = customReq.clone({ params });
        return next.handle(customReq);
    }
}
