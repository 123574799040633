import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { PwaServiceService } from './pwa-service.service';
import { isPlatformBrowser } from '@angular/common';
import { ApiHelperService } from 'projects/apna-lib/src/lib/services/api-helper.service';
import { CompanyIdentityService } from 'projects/apna-lib/src/lib/services/company-identity-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public title = 'accounting';
  public promptEvent: any;
  public companyDetails: any;
  public allowedDomain: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    public Pwa: PwaServiceService,
    public service: ApiHelperService,
    private company: CompanyIdentityService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      window?.addEventListener('beforeinstallprompt', event => {
        this.promptEvent = event;
      });
    }
    this.company.companyDetails.subscribe(company => {
      if (company) {
        this.companyDetails = company;
      } else if (company === false ){
        this.allowedDomain = location.hostname == 'localhost' || location.hostname == 'erp.local' || location.hostname == 'erp.apnasite.in' ? true : false;
      }
    })


  }

  async installPwa() {
    this.promptEvent.prompt();

    // Find out whether the user confirmed the installation or not
    const { outcome } = await this.promptEvent.userChoice;
    // The this.promptEvent can only be used once.
    this.promptEvent = null;
    // Act on the user's choice
    if (outcome === 'accepted') {
      console.log('User accepted the install prompt.');
      this.service.openSnackBar('App installed successfully. Enjoy using our app.');
    } else if (outcome === 'dismissed') {
      console.log('User dismissed the install prompt');
      this.service.openSnackBar('App not installed. Please reload page to install App.');
    }

  }

}
