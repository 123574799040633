import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { ServicesModule } from 'projects/apna-lib/src/lib/services/services.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { ReactiveFormsModule } from '@angular/forms'; 
import { SolveAssignmentComponent } from './modules/test/test-components/solve-assignment/solve-assignment.component';
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
     ReactiveFormsModule,
    // BrowserTransferStateModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    // TransferHttpCacheModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ServicesModule.forRoot(environment),
    ServiceWorkerModule.register('./ngsw-worker.js', {
      // enabled: environment.production,
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    [ { provide: DEFAULT_CURRENCY_CODE, useValue: 'INR' } ]
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
