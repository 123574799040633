import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FrontEndLoaderService {

  public defaultData: any = {
    WidgetsObject: {
      WidgetLeft: [],
      WidgetRight: [],
      WidgetTop: [],
      WidgetBottom: [],
      WidgetContentTop: [],
      WidgetContentBottom: [],
    }
  }

  public defaultDonfig: any = {
    // Page Classes
    pageWrapperClass: 'py-5',
    pageContainerClass: 'container',
    pageSectionClass: 'row align-items-start py-3',

    // Article/Page Classes
    articleImageWrapperClass: 'card card-body',
    articleImageClass: 'img-fluid',
    articleHeaderClass: 'border-bottom pb-3 mb-3',
    articleHeadingClass: 'fs-2',
    articleSubHeadingClass: 'fs-4 fw-normal',
    articleIntroClass: 'border p-3 rounded my-3 bg-secondary text-white',
    articleWrapperClass: 'col py',
    bodyWppaerClass: 'col',
    articleClass: 'd-flex flex-row align-items-start',

    // TOC Classes
    tocHeading: 'Contents',
    tocWrapperClass: 'position-sticky top-0 me-3 col-2',
    tocNavClass: 'nav flex-column border rounded py-2',
    navItemClass: 'nav-item',
    navItemLinkClass: 'nav-link',
    navItemArrowClass: 'text-dark',

    // Meta Data Classes
    // Publish Date
    publishDateWrapperClass: 'me-3',
    publishDateIconClass: 'bi bi-calendar',
    publishDateClass: 'ms-2',
    
    // Author
    authorWrapperClass: 'me-3',
    authorIconClass: 'bi bi-user',
    authorClass: 'ms-2',

    // Reading Time
    readingTimeWrapperClass: 'me-3',
    readingTimeIconClass: 'bi-hourglass-split',
    readingTimeLabelClass: 'ms-1',
    readingTimeClass: 'ms-2',

    // Social Share
    socialShareClass: 'sticky-top',

    // Review Classes
    reviewHeading: 'Review',
    reviewHeadingClass: 'border-bottom',
    reviewWrapperClass: 'd-block',

    // Category Classes
    categoryNoDataFoundClass: 'alert alert-warning fs-2 text-center m-5',
    categoryWrapperClass: 'd-block col',

    categoryHeadingWrapperClass: 'card card-body mb-3 bg-light',
    categoryHeadingClass: 'fs-2 fw-normal border-bottom pb-2 mb-3',
    categorySubHeadingClass: 'fs-4 fw-normal mb-2',
    categoryDescriptionClass: '',

    categorySearchSectionWrapperClass: 'row',
    categorySearchSidebarSectionClass: 'col-3',
    categorySearchSidebarHeadingClass: 'border-bottom pb-2 mb-2',
    categorySearchSidebarHeading: 'Sub Categories',
    categorySearchResultSectionClass: 'col',
    categorySearchSidebarListGroupClass: 'list-group',
    categorySearchSidebarListItemClass: 'list-group-item',
    categorySearchSidebarListLinkClass: 'list-group-item-action',

    categorySearchWrapperClass: '',
    categorySearchClass: 'd-block',
    categorySearchFilterClass: 'd-block',
    categorySearchResultWrapperClass: 'd-block',
    categorySearchItemWrapperClass: 'col-lg-3 col-md-4 col-sm-6',
    categorySearchItemClass: 'card',
    categorySearchItemImageWrapperClass: 'img-wrapper card-img-top',
    categorySearchItemImageClass: 'img-fluid',
    categorySearchItemContentClass: 'card-body',
    categorySearchItemHeadingClass: 'card-title',
    categorySearchItemLinkClass: 'btn btn-outline-primary',
    categorySearchItemLinkLabel: 'View Details',

    // Widget Classes
    widgetTopWrapperClass: '',
    widgetContentTopWrapperClass: '',
    widgetLeftWrapperClass: 'col-3',
    widgetRightWrapperClass: 'col-3',
    widgetContentRightWrapperClass: 'col-3',
    widgetBottomWrapperClass: '',

    widgetTopClass: 'row py-3',
    widgetContentTopClass: 'row py-3',
    widgetLeftClass: '',
    widgetRightClass: '',
    widgetBottomClass: 'row py-3',
    widgetContentBottomClass: 'row py-3',

    widgetTopHeadingClass: 'pb-3 mb-3 border-bottom',
    widgetContentTopHeadingClass: 'pb-3 mb-3 border-bottom',
    widgetLeftHeadingClass: 'pb-3 mb-3 border-bottom',
    widgetRightHeadingClass: 'pb-3 mb-3 border-bottom',
    widgetBottomHeadingClass: 'pb-3 mb-3 border-bottom',
    widgetContentBottomHeadingClass: 'pb-3 mb-3 border-bottom',

    // Widget Config
    widgetTop: false,
    widgetContentTop: false,
    widgetLeft: false,
    widgetRight: false,
    widgetBottom: false,
    widgetContentBottom: false,

    // Category Config
    showCategoryHeading: true,
    showCategorySubHeading: false,
    showCategoryDescription: true,
    showCategorySearch: false,
    showCategoryFilter: true,

    // Article Config
    showArticleImage: false,
    showArticleHeading: true,
    showArticleIntro: true,
    showArticleSubHeading: false,
    showArticleDescription: true,
    showArticleMetaData: true, // i.e. Author, Publish Date etc
    showArticleReviews: true, // i.e. Review form and reviews with reply
    socialShareLinks: true, // i.e. show social share links
    showTocLinks: false, // i.e. show table of contents on blog/page
    showReadingTime: true, // i.e. show table of contents on blog/page

  }

  private _pageData: BehaviorSubject<any> = new BehaviorSubject(null);

  get pageData(): Observable<any> {
    return this._pageData.asObservable();
  }

  set pageData(pageData: any) {
    this._pageData.next(pageData);
  }

  private _categoryData: BehaviorSubject<any> = new BehaviorSubject(null);

  get categoryData(): Observable<any> {
    return this._categoryData.asObservable();
  }

  set categoryData(categoryData: any) {
    this._categoryData.next(categoryData);
  }

  private _menuData: BehaviorSubject<any> = new BehaviorSubject(null);

  get menuData(): Observable<any> {
    return this._menuData.asObservable();
  }

  set menuData(menuData: any) {
    this._menuData.next(menuData);
  }

  private _formData: BehaviorSubject<any> = new BehaviorSubject(null);

  get formData(): Observable<any> {
    return this._formData.asObservable();
  }

  set formData(formData: any) {
    this._formData.next(formData);
  }

  private _webComponentData: BehaviorSubject<any> = new BehaviorSubject(null);

  get webComponentData(): Observable<any> {
    return this._webComponentData.asObservable();
  }

  set webComponentData(webComponentData: any) {
    this._webComponentData.next(webComponentData);
  }

  private _dataTableData: BehaviorSubject<any> = new BehaviorSubject(null);

  get dataTableData(): Observable<any> {
    return this._dataTableData.asObservable();
  }

  set dataTableData(dataTableData: any) {
    this._dataTableData.next(dataTableData);
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
  ) { }

  showLoader() {
    if (isPlatformBrowser(this.platformId)) {
      let loader: HTMLElement = this.document.querySelector('.loading-wrapper');
      if (loader) {
        loader.style.display = 'flex'
      }
    }
  }

  hideLoader() {
    if (isPlatformBrowser(this.platformId)) {
      let loader: HTMLElement = this.document.querySelector('.loading-wrapper');
      if (loader) {
        loader.style.display = 'none'
      }
    }
  }

  getData(dataKey: string) {
    if (isPlatformBrowser(this.platformId) && window[dataKey]) {
      return JSON.parse(atob(window[dataKey]));
    }
  }

  setData(dataKey: string, dataValue: any) {
    if (isPlatformServer(this.platformId)) {
      var head = this.document.getElementsByTagName('head')[0];
      var script = this.document.createElement('script');
      script.type = 'text/javascript';
      script.id = dataKey;
      let jsonStr = btoa(JSON.stringify(dataValue));
      script.text = `window.${dataKey} = "${jsonStr}";`;
      head.appendChild(script);
    }
  }
}
